import { useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { dbx } from "./utils/dropbox";

import "./App.css";

function App() {
    const containerRef = useRef(null);
    const [data, setData] = useState([]);
    // const [testData, setTestData] = useState([]);
    const [cursor, setCursor] = useState("");
    const [hasMore, setHasMore] = useState(false);

    const getSharedLinkPath = async () => {
        const data = await dbx.sharingGetSharedLinkMetadata({
            url: "https://www.dropbox.com/scl/fo/i69rzgkq4hqin8iolpov9/h?rlkey=ag7zyj92a9i61y8vkt9ydcemp&dl=0",
        });

        return data;
    };

    const getGroupedLinks = async (totalItems) => {
        if (totalItems?.length === 10) {
            const img1 = await getImageLink(totalItems[0]);
            const img2 = await getImageLink(totalItems[1]);
            const img3 = await getImageLink(totalItems[2]);
            const img4 = await getImageLink(totalItems[3]);
            const img5 = await getImageLink(totalItems[4]);

            const img6 = await getImageLink(totalItems[5]);
            const img7 = await getImageLink(totalItems[6]);
            const img8 = await getImageLink(totalItems[7]);
            const img9 = await getImageLink(totalItems[8]);
            const img10 = await getImageLink(totalItems[8]);

            return [
                img1,
                img2,
                img3,
                img4,
                img5,
                img6,
                img7,
                img8,
                img9,
                img10,
            ];
        }

        if (totalItems?.length === 7) {
            const img1 = await getImageLink(totalItems[0]);
            const img2 = await getImageLink(totalItems[1]);
            const img3 = await getImageLink(totalItems[2]);
            const img4 = await getImageLink(totalItems[3]);
            const img5 = await getImageLink(totalItems[4]);

            const img6 = await getImageLink(totalItems[5]);
            const img7 = await getImageLink(totalItems[6]);

            return [img1, img2, img3, img4, img5, img6, img7];
        }

        if (totalItems?.length === 5) {
            const img1 = await getImageLink(totalItems[0]);
            const img2 = await getImageLink(totalItems[1]);
            const img3 = await getImageLink(totalItems[2]);
            const img4 = await getImageLink(totalItems[3]);
            const img5 = await getImageLink(totalItems[4]);

            return [img1, img2, img3, img4, img5];
        } else if (totalItems?.length === 4) {
            const img1 = await getImageLink(totalItems[0]);
            const img2 = await getImageLink(totalItems[1]);
            const img3 = await getImageLink(totalItems[2]);
            const img4 = await getImageLink(totalItems[3]);
            return [img1, img2, img3, img4];
        } else if (totalItems?.length === 3) {
            const img1 = await getImageLink(totalItems[0]);
            const img2 = await getImageLink(totalItems[1]);
            const img3 = await getImageLink(totalItems[2]);
            return [img1, img2, img3];
        } else if (totalItems?.length === 2) {
            const img1 = await getImageLink(totalItems[0]);
            const img2 = await getImageLink(totalItems[1]);
            return [img1, img2];
        } else if (totalItems?.length === 1) {
            const img1 = await getImageLink(totalItems[0]);
            return [img1];
        }
    };

    const getImageLink = async (item) => {
        const path = item?.path_lower;

        try {
            const response = await dbx.sharingCreateSharedLinkWithSettings({
                path: path,
            });

            if (response.status === 200) {
                const imgURL = response.result.url.replace("dl=0", "raw=1");

                return {
                    url: imgURL,
                    id: response.result.id,
                };
            }
        } catch (err) {
            const { error } = err;
            const metaData = error?.error?.shared_link_already_exists?.metadata;

            const reqURL = metaData?.url?.replace("dl=0", "raw=1");

            return {
                url: reqURL,
                id: metaData?.id,
            };
        }
    };

    const getInitialData = async () => {
        // console.log("Func called");
        const sharedLinkPath = (await getSharedLinkPath()).result?.path_lower;

        const folders = await dbx.filesListFolder({
            path: sharedLinkPath,
            include_deleted: false,
            include_has_explicit_shared_members: false,
            include_media_info: false,
            include_mounted_folders: true,
            include_non_downloadable_files: true,
            recursive: false,
            limit: 5,
        });

        // const cursor = folders.result.cursor;
        // const hasMore = folders.result.has_more;

        if (folders.status === 200) {
            setCursor(folders.result.cursor);
            setHasMore(folders.result.has_more);
            const reqData = await getGroupedLinks(folders.result.entries);
            setData(reqData);
        }
    };

    const fetchNext = async () => {
        const folders = await dbx.filesListFolderContinue({
            cursor: cursor,
        });

        if (folders.status === 200) {
            setCursor(folders.result.cursor);
            setHasMore(folders.result.has_more);

            const reqData = await getGroupedLinks(folders.result.entries);
            setData((prev) => [...prev, ...reqData]);
        }
    };

    useEffect(() => {
        getInitialData();
    }, []);

    return (
        <InfiniteScroll
            dataLength={data.length}
            next={fetchNext}
            hasMore={hasMore}
            loader={
                <p
                    style={{
                        textAlign: "center",
                        fontSize: "30px",
                        fontWeight: "bold",
                    }}
                >
                    Loading...
                </p>
            }
        >
            <div className="grid" ref={containerRef}>
                {data?.map((item) => {
                    return (
                        <div key={item?.id} className="item">
                            <img src={item?.url} alt={"image"} />
                        </div>
                    );
                })}
            </div>
        </InfiniteScroll>
    );
}

export default App;
